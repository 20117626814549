import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

import ModalCreateService from '@/components/modals/modalCreateService'
import ModalUpdateTariff from '@/components/modals/modalUpdateTariff'

const Card = () => externalComponent('card')
const CardIcon = () => externalComponent('cardIcon')
const CardButton = () => externalComponent('cardButton')
const Toolbar = () => externalComponent('toolbar')
const Table = () => externalComponent('table')
const Pagination = () => externalComponent('pagination')
const FormButton = () => externalComponent('formButton')

const utils = require('./../../../utils')

export default {
  name: 'Stay',

  components: {
    Card,
    CardIcon,
    CardButton,
    Toolbar,
    Table,
    Pagination,
    ModalCreateService,
    FormButton,
    ModalUpdateTariff
  },

  data() {
    return {
      info: {
        title: 'Tarifa Yate',
        status: {},
        start_date: '',
        end_date: ''
      },
      ids: [],
      timeline: {
        selected: [],
        rows: [],
        pages: 0,
        currentPage: 1,
        section: 'charges',
        action: {
          name: 'Acciones',
          options: [{
              name: 'Activar',
              value: 'changeStatusTariffActive'
            }, {
              name: 'Posponer',
              value: 'changeStatusTariffInactive'
            // }, {
            //   name: 'Editar',
            //   value: 'tariffEdit'
            }, {
              name: 'Eliminar',
              value: 'deleteTariff'
          }]
        },
        // search: {
        //   name: 'Propiedad',
        //   placeholder: 'Propiedad'
        // },
        mainAction: {
          name: 'Agregar tarifa',
          emit: 'showModalUpdateTariff'
        },
        filter: {
          name: 'Estado',
          options: [
            {
              name: 'Aprobada',
              value: 'open'
            },
            {
              name: 'Denegada',
              value: 'close'
            },
            {
              name: 'Revisión',
              value: 'pending'
            },
          ]
        },
        sections: [
          {
            name: 'Cargos',
            value: 'charges'
          },
          {
            name: 'Ordenes de pago',
            value: 'orders'
          }
        ],
        columns: [
          {
            name: 'Tarifa',
            checkbox: true
          },
          {
            name: 'Precio'
          },
          {
            name: 'Estado'
          }
        ]
      },

      isModalUpdateTariff: false,
      isModalCreateService: false,
    }
  },

  methods: {
    getTariffs: async function() {
      let response;

      await axios
        .get(apiDomain + '/tariff')
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.timeline.rows = response.data;
        
        this.timeline.rows.forEach(element => {
          element[1].value = utils.getMoneyFormat(element[1].value);
        });
      } else {
        this.$toast.error('Error en la consulta.');
      }
    },

    updateTariff: async function() {
      let response;

      await axios
        .post(apiDomain + '/tariff')
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.timeline.rows = response.data;
      } else {
        this.$toast.error('Error en la consulta.');
      }
    },

    deleteTariff: async function() {
      if( this.timeline.selected.length > 0 ) {
        await axios
        .delete(apiDomain + '/tariff', { data: this.timeline.selected })
        .then(res => {
          let response = res;

          if (typeof response !== 'undefined' && response.status === 200) {
            this.timeline.rows = response.data;
          } else {
            this.$toast.error('Error en la consulta.');
          }

          this.getTariffs()
        })
        .catch(err => error = true);
      }
    },

    changeStatusTariffActive: async function() {
      if( !this.timeline.selected.length ) {
        this.$toast.warning('Selecciona las tarifas que quieras activar');
        return
      }

      let response;

      await axios
        .post(apiDomain + '/tariff/changeStatusActive', this.timeline.selected)
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.$toast.success('Tarifas Actualizada.');
        this.getTariffs();
      } else {
        this.$toast.error('Error en la consulta');
      }
    },

    changeStatusTariffInactive: async function() {
      if( !this.timeline.selected.length ) {
        this.$toast.warning('Selecciona las tarifas que quieras desactivar');
        return
      }

      let response;

      await axios
        .post(apiDomain + '/tariff/changeStatusInactive', this.timeline.selected)
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.$toast.success('Tarifas Actualizada.');
        this.getTariffs();
      } else {
        this.$toast.error('Error en la consulta');
      }
    },

    doAction: function(action) {
      switch (action) {
        case 'deleteTariff':
          this.deleteTariff();
          break;
        case 'changeStatusTariffActive':
          this.changeStatusTariffActive();
          break;
        case 'changeStatusTariffInactive':
          this.changeStatusTariffInactive();
          break;
      }
    },

    extra: function(id) {
    },

    showModalUpdateTariff: function() {
      this.isModalUpdateTariff = true;
    },

    hideModalUpdateTariff: function() {
      this.isModalUpdateTariff = false;
      this.getTariffs();
    }
  },

  mounted() {
    this.getTariffs();

    EventBus.$on('hideModalUpdateTariff', () => {
      this.hideModalUpdateTariff();
    });
  }
}
