import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'
import { Money } from 'v-money'

const Modal = () => externalComponent('modal')
const FormInput = () => externalComponent('formInput')

export default {
  name: 'ModalUpdateTariff',

  components: {
    Modal,
    FormInput,
    Money
  },

  props: ['mode'],

  data() {
    return {
      tariff: {
        title: '',
        tariff: '',
        time: '',
        guestLimit: ''
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: ' #',
        precision: 2,
        masked: false
      }
    }
  },

  methods: {
    changeTariff: function(e) {
      // console.log(e);
    },
     
    close: function() {
      EventBus.$emit('hideModalUpdateTariff');
    },

    saveService: async function() {
      this.tariff.price = this.tariff.price*100;
      
      let data = this.tariff,
        response;


      await axios
      .post(apiDomain + "/tariff", data)
      .then(res => response = res)
      .catch(err => response = err);
      
      if (typeof response !== 'undefined' && response.status === 200) {
        this.$toast.success('Tarifa guardado con exito.');
        this.close();
      } else {
        this.$toast.error('Error al intentar guardar la tarifa.');
      }
    },

    formatNumber: function(n) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // ^([^\d\s]{1,}\s?[+-]?)(\d{1,3})(\,\d{3})*(\.\d{1,})?$
      // return n.replace(/\D/g, "").replace(/^(-)?(\d+)(?:[\s\.]0+)?\s*(?:USD)?$/g, '$1$$$2');
    },

    formatCurrency: function(input, blur) {
      // appends $ to value, validates decimal side
      // and puts cursor back in right position.
      
      // get input value
      var input_val = input;
      
      // don't validate empty input
      if (input_val === "") { return; }
      
      // original length
      var original_len = input_val.length;

      // initial caret position 
      // var caret_pos = input.prop("selectionStart");
        
      // check for decimal
      if (input_val.indexOf(".") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(".");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = this.formatNumber(left_side);

        // validate right side
        right_side = this.formatNumber(right_side);
        
        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
          right_side += "00";
        }
        
        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = "$" + left_side + "." + right_side;

      } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = this.formatNumber(input_val);
        input_val = "$" + input_val;
        
        // final formatting
        if (blur === "blur") {
          input_val += ".00";
        }
      }
      
      // send updated string to input
      // input.val(input_val);

      // put caret back in the right position
      var updated_len = input_val.length;
      caret_pos = updated_len - original_len + caret_pos;
      input[0].setSelectionRange(caret_pos, caret_pos);
    }

  },

  mounted() {
    // console.log(this.mode);
  },

  watch: {
    // 'tariff.price': function (newQuestion, oldQuestion) {
    //   let value = this.tariff.price;
    //   this.tariff.price = this.formatNumber(value);
    // }
  }
}
