/**
 * Created by Esquivies on 2021/11/04.
 */

/**
 * @param {Number} amount
 * @returns {String}
 */
 export function getMoneyFormat(amount) {
  let number;
  let format;

  amount = String(amount).replace('$', '').replace(',', '');
  number = parseFloat(amount).toFixed(2);

  format = new Intl.NumberFormat('es-MX', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(number);

  return '$' + format;
}
